import { useSession } from '@blitzjs/auth';
import { useEffect } from 'react';

const LOGGED_IN_CLASS = 'is-logged-in';

export default function AuthAware() {
    const session = useSession({ suspense: false });

    useEffect(() => {
        if (session.userId) {
            document.body.classList.add(LOGGED_IN_CLASS);
        } else {
            document.body.classList.remove(LOGGED_IN_CLASS);
        }
    }, [session]);

    return null;
}
