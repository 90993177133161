import { AuthClientPlugin } from '@blitzjs/auth';
import { setupBlitzClient } from '@blitzjs/next';
import { BlitzRpcPlugin } from '@blitzjs/rpc';

export const authConfig = {
    cookiePrefix: 'portal',
};

export const useQueryDefaultOptions = {
    suspense: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
};

export const { withBlitz } = setupBlitzClient({
    plugins: [
        AuthClientPlugin(authConfig),
        BlitzRpcPlugin({
            reactQueryOptions: {
                queries: useQueryDefaultOptions,
            },
        }),
    ],
});
