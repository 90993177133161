// log the pageview with their URL
export const pageview = (url) => {
    // @ts-ignore
    if (typeof window === 'undefined' || !window.gtag) return;

    // @ts-ignore
    window.gtag('set', 'page', url);
};

// log specific events happening.
export const event = ({ action, params = null }: { action: string; params?: any }) => {
    // @ts-ignore
    if (typeof window === 'undefined' || !window.gtag) return;

    if (params) {
        // @ts-ignore
        window.gtag('event', `${action}-${params}`);
    } else {
        // @ts-ignore
        window.gtag('event', action);
    }
};

export type GAUserConfig = {
    userId?: number;
    activeMPHolder: boolean;
    IRLTicketHolder: boolean;
    teamMPHolder: boolean;
};

export const setUserData = ({ userId, activeMPHolder, IRLTicketHolder, teamMPHolder }: GAUserConfig) => {
    // @ts-ignore
    if (typeof window === 'undefined' || !window.gtag) return;

    if (userId) {
        // @ts-ignore
        window.gtag('set', {
            user_id: userId,
        });
    }
    // @ts-ignore
    window.gtag('set', 'user_properties', {
        active_mp_holder: activeMPHolder,
        irl_ticket_holder: IRLTicketHolder,
        team_mp_holder: teamMPHolder,
    });
};

export const trackEvent = event;
