import Script from 'next/script';

export function Counters() {
    return (
        <>
            {process.env.MACHINE === 'production' && (
                <>
                    <Script src="https://www.googletagmanager.com/gtag/js?id=G-LTS6WTS6HV" />
                    <Script
                        id="gtag"
                        dangerouslySetInnerHTML={{
                            __html: `window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    gtag('config', 'G-LTS6WTS6HV');`,
                        }}
                    />

                    {/*<script async src="https://www.googletagmanager.com/gtag/js?id=AW-805657772"></script>*/}
                    {/*<script*/}
                    {/*    dangerouslySetInnerHTML={{*/}
                    {/*        __html: `window.dataLayer = window.dataLayer || [];*/}
                    {/*                function gtag(){dataLayer.push(arguments);}*/}
                    {/*                gtag('js', new Date());*/}
                    {/*                */}
                    {/*                gtag('config', 'AW-805657772');`,*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Script
                        id="gaug"
                        dangerouslySetInnerHTML={{
                            __html: `var _gauges = _gauges || [];
                        (function() {
                        var t   = document.createElement('script');
                        t.type  = 'text/javascript';
                        t.async = true;
                        t.id    = 'gauges-tracker';
                        t.setAttribute('data-site-id', '627c1e2e7a314007ba92644b');
                        t.setAttribute('data-track-path', 'https://track.gaug.es/track.gif');
                        t.src = 'https://d2fuc4clr7gvcn.cloudfront.net/track.js';
                        var s = document.getElementsByTagName('script')[0];
                        s.parentNode.insertBefore(t, s);
                    })();`,
                        }}
                    />

                    <Script
                        id="cloudflareinsights"
                        dangerouslySetInnerHTML={{
                            __html: `
                    var _cloudflare = document.createElement('script')
                    _cloudflare.src = 'https://static.cloudflareinsights.com/beacon.min.js'
                    _cloudflare.setAttribute('data-cf-beacon', '{"token": "b0a3795d18e74739845c6df47f703132"}')
                    document.body.appendChild(_cloudflare)
                    `,
                        }}
                    />
                </>
            )}
        </>
    );
}
