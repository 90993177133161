import { AppProps, ErrorBoundary, ErrorComponent, ErrorFallbackProps } from '@blitzjs/next';
import { Prisma } from '@gn/db/client';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { pageview } from 'app/analytics';
import { withBlitz } from 'app/blitz-client';
import AuthAware from 'app/core/components/AuthAware';
import { AuthenticationError } from 'blitz';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import SuperJson from 'superjson';
import { ThemeProvider } from 'next-themes';
import dynamic from 'next/dynamic';
import { LinkedinAuthorizationError, PortalAuthorizationError, TwitterAuthorizationError, EmailInUseError } from 'app/auth/errors';
import { Counters } from 'app/core/components/Counters';
const LoginForm = dynamic(() => import('app/auth/components/LoginForm'));
SuperJson.registerClass(PortalAuthorizationError, {
  identifier: 'PortalAuthorizationError'
});
SuperJson.registerClass(TwitterAuthorizationError, {
  identifier: 'TwitterAuthorizationError'
});
SuperJson.registerClass(LinkedinAuthorizationError, {
  identifier: 'LinkedinAuthorizationError'
});
SuperJson.registerClass(EmailInUseError, {
  identifier: 'EmailInUseError'
});
SuperJson.registerCustom<Prisma.Decimal, number>({
  isApplicable: (v): v is Prisma.Decimal => Prisma.Decimal.isDecimal(v),
  serialize: v => v.toNumber(),
  deserialize: v => new Prisma.Decimal(v)
}, 'DecimalJS');
if (process.env.RUNTIME_ENV === 'server') {
  if (!globalThis['serverImportExecuted']) {
    import('../integrations/mezmo/logger');
  }
  globalThis['serverImportExecuted'] = true;
}
export default withBlitz(function App({
  Component,
  pageProps
}: AppProps) {
  const getLayout = Component.getLayout || (page => page);
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = url => {
      pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // disable prefetch on preview envs to save on resources
  if (process.env.MACHINE === 'preview') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      router.prefetch = async () => {};
    }, [router]);
  }
  return <>
            <ThemeProvider defaultTheme={'system'}>
                <QueryErrorResetBoundary>
                    {({
          reset
        }) => <ErrorBoundary FallbackComponent={RootErrorFallback} resetKeys={[router.asPath]} onReset={() => {
          // This ensures the Blitz useQuery hooks will automatically refetch
          // data any time you reset the error boundary
          reset();
        }}>
                            <AuthAware />
                            {getLayout(<Component {...pageProps} />)}
                        </ErrorBoundary>}
                </QueryErrorResetBoundary>
            </ThemeProvider>
            <Counters />
        </>;
});
function RootErrorFallback({
  error,
  resetErrorBoundary
}: ErrorFallbackProps) {
  if (error.name === 'AuthenticationError') {
    return <LoginForm mode="sso" onSuccess={resetErrorBoundary} fromErrorBoundary={(error as AuthenticationError)} />;
  } else if (error.name === 'AuthorizationError') {
    return <ErrorComponent statusCode={error.statusCode} title="Sorry, you are not authorized to access this" />;
  } else {
    return <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />;
  }
}