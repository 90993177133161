export class TwitterAuthorizationError extends Error {
    name = 'TwitterAuthorizationError';
    statusCode = 401;

    constructor(message = 'Twitter authorization error') {
        super(message);
    }
}

export class LinkedinAuthorizationError extends Error {
    name = 'LinkedinAuthorizationError';
    statusCode = 401;

    constructor(message = 'LinkedIn authorization error') {
        super(message);
    }
}

export class PortalAuthorizationError extends Error {
    name = 'PortalAuthorizationError';
    statusCode = 401;

    constructor(message = 'Portal authorization error') {
        super(message);
    }
}

export class EmailInUseError extends Error {
    name = 'EmailInUseError';
    statusCode = 500;

    constructor(message = 'Email is already in use') {
        super(message);
    }
}

export class AccountClaimedError extends Error {
    name = 'AccountClaimedError';
    statusCode = 409;

    constructor(message = 'Failed to claim an account, contact support') {
        super(message);
    }
}

export class AccountAssignedError extends Error {
    name = 'AccountAssignedError';
    statusCode = 409;
    email?: string;

    constructor(email?: string) {
        let message = 'Social account is already assigned to another user';
        if (email) {
            message += ' - ' + email;
        }

        super(message);
        this.email = email;
    }

    toString() {
        if (this.email) {
            return this.message;
        }

        return `${this.name}: ${this.message}`;
    }
}
